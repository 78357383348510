
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PasswordForm extends Vue {
  @Prop({ type: String })
  private readonly password!: string;

  private get isAtLeast8() {
    return this.password.length >= 8 && this.password.length <= 20;
  }

  private get isSpecial() {
    return RegExp(/[_!-/:-@¥[-`{-~]/).test(this.password);
  }

  //英大小文字と数字を含む
  private get isContainsLsn() {
    return RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/).test(this.password);
  }
}
