
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class Header extends Vue {
  private routeName = this.$route.name;

  @Watch("$route", { immediate: true, deep: true })
  private changeRoute() {
    if (this.$route.name) this.routeName = this.$route.name;
  }
}
