
import { Component, Vue } from "vue-property-decorator";
import NotAuthHeader from "@/components/NotAuthHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import PasswordForm from "@/components/forms/components/PasswordForm.vue";
import FacilityService from "@/services/facilities";
import Cognito from "@/plugins/cognito";

@Component({
  name: "ResetPassword",
  components: {
    NotAuthHeader,
    ManagementFooter,
    PasswordForm,
  },
})
export default class ResetPassword extends Vue {
  private password = "";
  private confirmPassword = "";
  private verificationCode = "";
  private id = this.$route.query.code as string;
  private email = "";
  private loading = false;
  private showPass = false;
  private showPassConfirm = false;
  private showLabel = false;
  private showError() {
    this.showLabel = true;
    setTimeout(() => {
      this.showLabel = false;
    }, 3000);
  }

  private async mounted() {
    try {
      if (this.id) {
        const res = await FacilityService.getResetPasswordInfo(this.id);
        this.email = res.data.data[0].email;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async submit() {
    try {
      this.loading = true;
      await Cognito.resetPassword(this.email, this.password, this.verificationCode);
      this.$router.push({ name: "ResetPasswordComplete" });
      this.loading = false;
    } catch (error: any) {
      this.showError();
      this.loading = false;
      throw new Error(error);
    }
  }

  private passwordToggle() {
    this.showPass = !this.showPass;
  }
  private confirmPasswordToggle() {
    this.showPassConfirm = !this.showPassConfirm;
  }
}
